import '../../components/cards/course.js';
import Typed from 'typed.js';

let placeholder = document.getElementById('learningPointPlaceholder');
let initialText = placeholder.textContent;

let texts = document.getElementById('learningPoints').textContent.split(', ');
texts.splice(0, 1, initialText);

placeholder.innerText = ''; // eslint-disable-line unicorn/prefer-dom-node-text-content

new Typed('#learningPointPlaceholder', {
    strings: texts,
    loop: true,
    typeSpeed: 80,
    backDelay: 900,
    backSpeed: 40,
    preStringTyped: (arrayPos, self) => {
        let currentText = placeholder.textContent;
        placeholder.innerText = self.options.strings[arrayPos]; // eslint-disable-line unicorn/prefer-dom-node-text-content
        placeholder.innerText = currentText; // eslint-disable-line unicorn/prefer-dom-node-text-content
    },
});

/** [no description; please add] */
function initializeDyamicTextArea() {
    const dynamicTextArea = document.querySelector('.dynamicHeroText');

    if (!dynamicTextArea) {
        return;
    }

    texts.forEach(text => {
        const panel = document.createElement('div');
        panel.classList.add('dynamicHeroText__panel');
        panel.innerHTML = `<span class="dynamicHeroText__prefix">Learn </span>${text}`;

        const cursor = document.createElement('span');
        cursor.classList.add('typed-cursor');
        cursor.textContent = '|';

        panel.append(cursor);

        dynamicTextArea.append(panel);
    });
}

initializeDyamicTextArea();
